import { lazy } from "react";

const HomePage = lazy(() => import("src/Components/HomePage"));
const NoMatchPage = lazy(() => import("src/Components/NoMatch"));
const PrivacyPage = lazy(() => import("src/Components/PrivacyPage"));
const FeaturePage = lazy(() => import("src/Components/FeaturePage"));
const TermsPage = lazy(() => import("src/Components/TermsPage"));
const MerchantPage = lazy(() => import("src/Components/MerchantPage"));
const DownloadPage = lazy(() => import("src/Components/DownloadPage"));
const ContactUsPage = lazy(() => import("src/Components/ContactUsPage"));
const RegisterSuccessPage = lazy(() =>
  import("src/Components/RegistrationSuccessPage")
);

const FAQPage = lazy(() => import("src/Components/FAQPage"));

export const NAVIGATION_ROUTES = Object.freeze({
  // Root routes
  home: {
    path: "/",
    exact: true,
    element: <HomePage />,
  },

  // feature
  feature: {
    path: "/consumer",
    element: <FeaturePage />,
  },

  // merchant
  merchant: {
    path: "/merchant",
    element: <MerchantPage />,
  },

  // Privacy Page
  Privacy: {
    path: "/privacy",
    element: <PrivacyPage />,
  },
  // Terms Page
  Terms: {
    path: "/terms",
    element: <TermsPage />,
  },

  // will have to be changed in the future
  RegisterSuccess: {
    path: "/register",
    search: "?success=true",
    element: <RegisterSuccessPage />,
  },

  // 404 - Must be the last item of the object
  noMatch: {
    path: "*",
    element: <NoMatchPage />,
  },

  // Download Page
  Download: {
    path: "/download",
    element: <DownloadPage />,
  },

  // contactUs Page
  ContactUs: {
    path: "/contact-us",
    element: <ContactUsPage />,
  },

  // faq page
  FAQPage: {
    path: "/faq",
    element: <FAQPage />,
  },
});
