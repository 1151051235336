import { REGIONS } from "./Constants";

export const regionGetter = () => {
  const url = window.location.href;
  // changed value to SG for default for SG
  let region = REGIONS.singapore;

  const split = String(url).split(".");
  if (split.length > 1) {
    const urlReg = split[split.length - 1];
    const reg = Object.values(REGIONS).find(
      (r) => r === String(urlReg).toLowerCase()
    );
    if (reg) region === reg;
  }
  return region;
};
