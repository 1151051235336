// Career Link
const careerSgLink = "https://careers.shopee.sg/";

// Product Link
const productLink = "https://product.shopeepay.com";

// header menu
export const headerEngMenu = [
  { name: "Home", path: "/" },
  { name: "Feature", path: "/feature" },
  { name: "Merchant", path: "/merchant" },
  { name: "FAQ", path: "/faq" },
  { name: "Career", path: "/career" },
];

// header menu
export const headerMyMenu = [
  { name: "Halaman Utama ", path: "/" },
  { name: "Ciri", path: "/feature" },
  { name: "Peniaga", path: "/merchant" },
  { name: "Kerjaya", path: "/career" },
  { name: "Soalan Lazim", path: "/faq" },
];

export const headerSgMenu = [
  { name: "Home", path: "/" },
  { name: "Consumer", path: "/consumer" },
  { name: "Merchant", path: "/merchant" },
  { name: "FAQ", path: "/faq" },
  { name: "Career", path: careerSgLink },
  { name: "Product", path: productLink },
];

export const downloadEng = "Download";
export const downloadMy = "Muat Turun";

// career link
export const careerMyLink = "https://careers.shopee.com.my";

// FAQ link
export const faqMyLink =
  "https://help.shopee.com.my/my/s/global-search/shopeepay";
export const faqSgLink =
  "https://help.shopee.sg/s/topic/0TO6F00000057YVWAY/shopeepay";

// language toggle value string since local storage value does not take boolean value

export const TRUE = "TRUE";
export const FALSE = "FALSE";
