import { combineReducers } from "redux";

// Reducers
import * as MenuSelectionBarReducer from "src/Common/Components/MenuSelectionBar/Redux/Reducer";
import * as RegionLanguageReducer from "src/Common/Redux/Reducer";
import * as CurrentUrlPageReducer from "src/RootRedux/UrlRedux/Reducer";

export const combinedReducers = combineReducers({
  // HomePage
  ...MenuSelectionBarReducer,
  ...RegionLanguageReducer,
  ...CurrentUrlPageReducer,
});
