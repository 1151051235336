// external modules
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// internal modules
import {
  setCurrentUrl,
  setClickedMerchantFeatures,
  setClickedShopeePayApp,
} from "src/RootRedux/UrlRedux/Actions";
import {
  shopeeLogoEng,
  QREng,
  socialsSg,
  bankIconsEng,
  appStoresSg,
  footerLeftTextEng,
  footerLeftDownloadEng,
  footerLeftUpperTitleEng,
  footerRightUpperTitleEng,
  leftBoxLinksEng,
  rightBoxLinksEng,
  footerLowerTextEng,
} from "./Constants";
import { Body1, Footnote } from "src/Common/Theme/Typography";
import { isDesktop } from "isDesktop";
import { REGIONS } from "src/Services/RegionGetter/Constants";
import { setSelectionMenu } from "../MenuSelectionBar/Redux/Actions";
import { left, right } from "../MenuSelectionBar/Constants";
import "./style.less";

export const AppFooter = () => {
  const dispatch = useDispatch();

  // reducer
  const regionLanguage = useSelector((state) => state.RegionLanguageReducer);
  const { region, language } = regionLanguage;

  // state
  const [footerLeftText, setFooterLeftText] = useState("");
  const [footerLeftDownload, setFooterLeftDownload] = useState("");
  const [footerLeftUpperTitle, setFooterLeftUpperTitle] = useState("");
  const [footerRightUpperTitle, setFooterRightUpperTitle] = useState("");
  const [leftBoxLinks, setLeftBoxLinks] = useState([]);
  const [rightBoxLinks, setRightBoxLinks] = useState([]);
  const [footerLowerText, setFooterLowerText] = useState("");
  const [shopeeLogo, setShopeeLogo] = useState("");
  const [QR, setQR] = useState([]);
  const [socialIcons, setSocialIcons] = useState([]);
  const [appStoreIcons, setAppStoreIcons] = useState([]);
  const [bankIcons, setBankIcons] = useState([]);

  // effect
  useEffect(() => {
    switch (true) {
      case region === REGIONS.singapore:
        setFooterLeftText(footerLeftTextEng);
        setFooterLeftDownload(footerLeftDownloadEng);
        setFooterLeftUpperTitle(footerLeftUpperTitleEng);
        setFooterRightUpperTitle(footerRightUpperTitleEng);
        setLeftBoxLinks(leftBoxLinksEng);
        setRightBoxLinks(rightBoxLinksEng);
        setFooterLowerText(footerLowerTextEng);
        setShopeeLogo(shopeeLogoEng);
        setSocialIcons(socialsSg);
        setQR(QREng);
        setAppStoreIcons(appStoresSg);
        setBankIcons(bankIconsEng);

        break;
      default:
    }
  }, [language, region]);

  // for color change of header
  const onButtonClick = (path) => {
    if (path === "/merchant") {
      dispatch(setCurrentUrl(path));
      dispatch(setClickedMerchantFeatures(true));
      dispatch(setClickedShopeePayApp(false));
    } else if (path === "/consumer/#App") {
      dispatch(setCurrentUrl(path));
      dispatch(setClickedShopeePayApp(true));
      dispatch(setSelectionMenu(right));
      dispatch(setClickedMerchantFeatures(false));
    } else if (path === "/consumer") {
      dispatch(setCurrentUrl(path));
      dispatch(setSelectionMenu(left));
      dispatch(setClickedShopeePayApp(false));
      dispatch(setClickedMerchantFeatures(false));
    } else if (
      path === "/privacy" ||
      path === "/terms" ||
      path === "/contact-us"
    ) {
      dispatch(setCurrentUrl(path));
      dispatch(setClickedMerchantFeatures(false));
      dispatch(setClickedShopeePayApp(false));
    } else {
      dispatch(setCurrentUrl(path));
      dispatch(setClickedMerchantFeatures(false));
      dispatch(setClickedShopeePayApp(false));
    }
  };

  const isRegionWithPromo =
    region !== REGIONS.malaysia && region !== REGIONS.singapore;

  return (
    <>
      {/* for screen size above 600px */}
      {isDesktop() ? (
        <div className="AppFooter">
          <div className="AppFooter__Wrapper">
            <div className="AppFooter__WidthContainer">
              <div className="AppFooter__LeftBox">
                <img
                  className="AppFooter__LeftBox__logo"
                  src={shopeeLogoEng}
                  alt=""
                />
                <div className="AppFooter__LeftBox__downloadText">
                  <Footnote>{footerLeftText}</Footnote>
                </div>

                {socialIcons.map((icon, index) => (
                  <a href={icon.link} key={index}>
                    <img
                      key={index}
                      className="AppFooter__LeftBox__socialMediaLogos"
                      src={icon.img}
                      alt={icon.description}
                    />
                  </a>
                ))}

                <div className="AppFooter__LeftBox__downloadText">
                  <Footnote>{footerLeftDownload}</Footnote>
                </div>
                <div>
                  {appStoreIcons.map((icon, index) => (
                    <a href={icon.link} key={index}>
                      <img
                        className="AppFooter__LeftBox__appsLogos"
                        src={icon.img}
                        alt={icon.description}
                        width={icon.width}
                        key={index}
                      />
                    </a>
                  ))}
                </div>
                {QR.map((icon, index) => (
                  <a href={icon.link} key={index}>
                    <img
                      src={icon.img}
                      alt={icon.description}
                      className="AppFooter__LeftBox__qr"
                      width={icon.width}
                    />
                  </a>
                ))}
              </div>
              <div className="AppFooter__RightBox">
                <div className="AppFooter__RightUpperBox">
                  <div className="AppFooter__RightUpperBox__termsTitle">
                    {footerLeftUpperTitle}
                  </div>
                  <div className="AppFooter__RightUpperBox__termsTitle">
                    {footerRightUpperTitle}
                  </div>
                  <div>
                    <Body1>
                      <Link
                        to="/consumer"
                        className="AppFooter__RightUpperBox__Link"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                          });
                          onButtonClick("/consumer");
                        }}
                      >
                        {leftBoxLinks.shopeePayFeatures}
                      </Link>
                    </Body1>
                    <Body1>
                      <Link
                        to="/consumer/#App"
                        className="AppFooter__RightUpperBox__Link"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                          });
                          onButtonClick("/consumer/#App");
                        }}
                      >
                        {leftBoxLinks.shopeePayApp}
                      </Link>
                    </Body1>
                    <Body1>
                      <Link
                        to="/merchant"
                        className="AppFooter__RightUpperBox__Link"
                        onClick={() => {
                          window.scrollTo({
                            top: 720,
                            left: 0,
                          });
                          onButtonClick("/merchant");
                        }}
                      >
                        {leftBoxLinks.merchantFeatures}
                      </Link>
                    </Body1>
                    {isRegionWithPromo && (
                      <Link
                        className="AppFooter__RightUpperBox__Link"
                        to="/promo"
                        onClick={() => {
                          window.scrollTo({
                            top: 720,
                            left: 0,
                          });
                          onButtonClick("/promo");
                        }}
                      >
                        {leftBoxLinks.promo}
                      </Link>
                    )}
                    <Link
                      className="AppFooter__RightUpperBox__Link"
                      to="/faq"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                        });
                        onButtonClick("/faq");
                      }}
                    >
                      {leftBoxLinks.faq}
                    </Link>
                  </div>
                  <div>
                    <Link
                      id="aboutUs"
                      to="/#about"
                      className="AppFooter__RightUpperBox__Link"
                      onClick={() => {
                        window.scrollTo({
                          top: 750,
                          left: 0,
                        });
                        onButtonClick("/");
                      }}
                    >
                      {rightBoxLinks.aboutUs}
                    </Link>
                    <Link
                      className="AppFooter__RightUpperBox__Link"
                      to="/terms"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        onButtonClick("/terms");
                      }}
                    >
                      {rightBoxLinks.termsConditions}
                    </Link>

                    <Link
                      className="AppFooter__RightUpperBox__Link"
                      to="/privacy"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        onButtonClick("/privacy");
                      }}
                    >
                      {rightBoxLinks.PrivacyPolicy}
                    </Link>
                    <Link
                      className="AppFooter__RightUpperBox__Link"
                      to="/contact-us"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        onButtonClick("/contact-us");
                      }}
                    >
                      {rightBoxLinks.contactUs}
                    </Link>
                  </div>
                </div>
                <div className="AppFooter__RightLowerBox">
                  <div className="AppFooter__RightLowerBox__termsTitle">
                    {footerLowerText}
                  </div>
                  <div className="AppFooter__RightLowerBox__RightLogos">
                    {bankIcons.map((icon, index) => (
                      <div
                        className="AppFooter__RightLowerBox__RightLogos__Logo"
                        key={index}
                      >
                        <img src={icon.img} width={icon.width} alt="logo" />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="AppFooter__horizontalLine">
              <div>
                <Footnote>
                  Copyright © Shopee Limited . Trademarks belong to their
                  respective owners. All rights reserved.
                  <Link
                    className={"AppFooter__horizontalLine__Footnote"}
                    to="/terms"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      onButtonClick("/terms");
                    }}
                  >
                    {" "}
                    | Terms of Service{" "}
                  </Link>
                  |
                  <Link
                    className={"AppFooter__horizontalLine__Footnote"}
                    to="/privacy"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      onButtonClick("/privacy");
                    }}
                  >
                    {" "}
                    Privacy Policy
                  </Link>
                </Footnote>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="AppFooterMobile">
          <div className="AppFooterMobile__TopBox">
            <img
              className="AppFooterMobile__TopBox__logo"
              src={shopeeLogo}
              alt=""
            />
          </div>
          <div className="AppFooterMobile__MiddleUpperBox">
            <div className="AppFooterMobile__MiddleUpperBox__termsTitle">
              {footerLeftUpperTitle}
            </div>

            <Link
              to={{
                pathname: "/consumer",
              }}
              style={{ textDecoration: "none", color: "#000000" }}
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                });
                onButtonClick("/consumer");
              }}
            >
              <p>
                <Body1 className="AppFooterMobile__MiddleUpperBox__itemNavMobile">
                  {leftBoxLinks.shopeePayFeatures}
                </Body1>
              </p>
            </Link>

            <p>
              <Link
                to={{
                  pathname: "/consumer/#App",
                }}
                style={{ textDecoration: "none", color: "#000000" }}
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                  });
                  onButtonClick("/consumer/#App");
                }}
              >
                <Body1 className="AppFooterMobile__MiddleUpperBox__itemNavMobile">
                  {leftBoxLinks.shopeePayApp}
                </Body1>
              </Link>
            </p>
            <p>
              <Link
                to={{
                  pathname: "/merchant/#merchantApp",
                }}
                style={{ textDecoration: "none", color: "#000000" }}
                onClick={() => {
                  window.scrollTo({
                    top: 480,
                    left: 0,
                  });
                  onButtonClick("/merchant");
                }}
              >
                <Body1 className="AppFooterMobile__MiddleUpperBox__itemNavMobile">
                  {leftBoxLinks.merchantFeatures}
                </Body1>
              </Link>
            </p>
            {isRegionWithPromo && (
              <p>
                <Link
                  to="/promo"
                  className="itemNav"
                  onClick={() => {
                    window.scrollTo({
                      top: 480,
                      left: 0,
                    });
                    onButtonClick("/promo");
                  }}
                >
                  <Body1 className="AppFooterMobile__MiddleUpperBox__itemNavMobile">
                    {leftBoxLinks.promo}
                  </Body1>
                </Link>
              </p>
            )}
            <Link
              to="/faq"
              className="itemNav"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                });
                onButtonClick("/faq");
              }}
            >
              <p>
                <Body1 className="AppFooterMobile__MiddleUpperBox__itemNavMobile">
                  {leftBoxLinks.faq}
                </Body1>
              </p>
            </Link>
            <div className="AppFooterMobile__MiddleUpperBox__termsTitle">
              {footerRightUpperTitle}
            </div>
            <div>
              <Link
                to={{ pathname: "/#aboutUs" }}
                style={{ textDecoration: "none", color: "#000000" }}
                onClick={() => {
                  window.scrollTo({
                    top: 650,
                    left: 0,
                  });
                  onButtonClick("/");
                }}
              >
                <p>
                  <Body1 className="AppFooterMobile__MiddleUpperBox__itemNavMobile">
                    {rightBoxLinks.aboutUs}
                  </Body1>
                </p>
              </Link>

              <p>
                <Link
                  to="/terms"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    onButtonClick("/terms");
                  }}
                >
                  <Body1 className="AppFooterMobile__MiddleUpperBox__itemNavMobile">
                    {rightBoxLinks.termsConditions}
                  </Body1>
                </Link>
              </p>
              <p>
                <Link
                  to="/privacy"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    onButtonClick("/privacy");
                  }}
                >
                  <Body1 className="AppFooterMobile__MiddleUpperBox__itemNavMobile">
                    {rightBoxLinks.PrivacyPolicy}
                  </Body1>
                </Link>
              </p>
              <Link
                to="/contact-us"
                onClick={() => {
                  window.scrollTo(0, 0);
                  onButtonClick("/contact-us");
                }}
              >
                <Body1 className="AppFooterMobile__MiddleUpperBox__itemNavMobile">
                  {rightBoxLinks.contactUs}
                </Body1>
              </Link>
            </div>
            <div className="AppFooterMobile__MiddleUpperBox__termsTitle__topUp">
              {footerLowerText}
            </div>
          </div>
          <div className="AppFooterMobile__MiddleLowerBox">
            <div className="AppFooterMobile__MiddleLowerBox__MiddleLogos">
              {bankIcons.map((icon, index) => (
                <div
                  className="AppFooterMobile__MiddleLowerBox__MiddleLogos__logo"
                  key={index}
                >
                  <img
                    key={index}
                    src={icon.img}
                    width={icon.width}
                    alt="Icon"
                  />
                </div>
              ))}
            </div>
            <p>{footerLeftText}</p>

            {socialIcons.map((icon, index) => (
              <a href={icon.link} key={index}>
                <img
                  className="AppFooterMobile__MiddleLowerBox__socialMediaLogos"
                  src={icon.img}
                  alt={icon.description}
                />
              </a>
            ))}

            <p className="paddings">{footerLeftDownload}</p>
          </div>
          <div className="AppFooterMobile__BottomBox">
            <div className="AppFooterMobile__BottomBox__items">
              {appStoreIcons.map((icon, index) => (
                <a href={icon.link} key={index}>
                  <img
                    className="AppFooterMobile__MiddleLowerBox__appsLogos"
                    src={icon.img}
                    alt={icon.description}
                    width={icon.width}
                  />
                </a>
              ))}
            </div>

            <div className="AppFooterMobile__BottomBox__divider">
              <Footnote className="AppFooterMobile__BottomBox__divider__horizontalLine">
                <div>
                  Copyright © Shopee Limited. Trademarks belong to their
                  respective owners. All rights reserved.
                  <div
                    className={`AppFooterMobile__BottomBox__divider__horizontalLine__Terms`}
                  >
                    <Link
                      className={
                        "AppFooterMobile__BottomBox__divider__horizontalLine__Terms__Footnote"
                      }
                      to="/terms"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        onButtonClick("/terms");
                      }}
                    >
                      Terms of Service{" "}
                    </Link>
                    |
                    <Link
                      className={
                        "AppFooterMobile__BottomBox__divider__horizontalLine__Terms__Footnote"
                      }
                      to="/privacy"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        onButtonClick("/privacy");
                      }}
                    >
                      {" "}
                      Privacy Policy
                    </Link>
                  </div>
                </div>
              </Footnote>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
