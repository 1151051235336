import {
  SET_CURRENT_URL,
  CLICKED_FOOTER_MERCHANT_FEATURES,
  CLICKED_FOOTER_TABS_NOT_ON_HEADER,
  CLICKED_FOOTER_SHOPEEPAY_APP,
} from "../Types";

const currentUrlInitialState = {};
const currentFooterMfInitialState = {};
const currentFooterClickedInitialState = {};

export const CurrentUrlReducer = (state = currentUrlInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CURRENT_URL: {
      return {
        ...state,
        currentUrl: payload,
      };
    }
    default:
      return state;
  }
};

export const currentClickedFooterReducer = (
  state = currentFooterMfInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case CLICKED_FOOTER_MERCHANT_FEATURES: {
      return {
        ...state,
        clickedMF: payload,
      };
    }
    case CLICKED_FOOTER_SHOPEEPAY_APP: {
      return {
        ...state,
        clickedSA: payload,
      };
    }

    default:
      return state;
  }
};

export const currentFooterClickedReducer = (
  state = currentFooterClickedInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case CLICKED_FOOTER_TABS_NOT_ON_HEADER: {
      return {
        ...state,
        clickedNotOnHeader: payload,
      };
    }
    default:
      return state;
  }
};
