// const variables
export const consumer = "consumer";
export const merchant = "merchant";
export const left = "left";
export const right = "right";

// feature text
export const featureSelectionLeftEngText = "ShopeePay Features";
export const featureSelectionLeftMyText = "Ciri-Ciri ShopeePay";

export const featureSelectionRightEngText = "Pay With ShopeePay";
export const featureSelectionRightMyText = "Aplikasi ShopeePay";

// merchant text
export const merchantSelectionLeftEngText = "Registration";
export const merchantSelectionLeftMyText = "Pendaftaran";
export const merchantSelectionRightEngText = "Merchant Features";
export const merchantSelectionRightMyText = "Ciri-Ciri Peniaga";
