import {
  SET_CURRENT_URL,
  CLICKED_FOOTER_MERCHANT_FEATURES,
  CLICKED_FOOTER_TABS_NOT_ON_HEADER,
  CLICKED_FOOTER_SHOPEEPAY_APP,
} from "../Types";

export const setCurrentUrl = (currentUrl) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_URL,
    payload: currentUrl,
  });
};

export const setClickedMerchantFeatures = (clickedMF) => (dispatch) => {
  dispatch({
    type: CLICKED_FOOTER_MERCHANT_FEATURES,
    payload: clickedMF,
  });
};

export const setClickedShopeePayApp = (clickedSA) => (dispatch) => {
  dispatch({
    type: CLICKED_FOOTER_SHOPEEPAY_APP,
    payload: clickedSA,
  });
};

export const setClickedFooterTabsNotOnHeader =
  (clickedNotOnHeader) => (dispatch) => {
    dispatch({
      type: CLICKED_FOOTER_TABS_NOT_ON_HEADER,
      payload: clickedNotOnHeader,
    });
  };
