/* REGION : MY */
import QR from "../../../Assets/Icons/ShopeeLogo/QR.png";
import shopeeQR from "../../../Assets/Icons/ShopeeLogo/shopeeQR.png";
import shopeeLogo from "../../../Assets/Icons/ShopeeLogo/shopeeLogo.svg";

// logo for SG
import telegramLogo from "../../../Assets/Icons/Socials/telegram.png";

//import all svg files for Socials
const reqSvgSocial = require.context(
  "src/Common/Assets/Icons/Socials/",
  true,
  /\.svg$/
);
const svgFileSocialPaths = reqSvgSocial.keys();
//import all svg files for App Stores
const reqSvgApp = require.context(
  "src/Common/Assets/Icons/AppStore/",
  true,
  /\.svg$/
);
const svgFileAppPaths = reqSvgApp.keys();

const reqPngIcons = require.context(
  "src/Common/Components/AppFooter/Assets/Icons/Png",
  true,
  /\.png$/
);
const pngFileIconsPaths = reqPngIcons.keys();

//images
//shopee logo
export const shopeeLogoEng = shopeeLogo;
export const shopeeLogoMy = shopeeLogo;
//Qr code
export const QREng = [
  {
    img: shopeeQR,
    link: " https://shopee.sg/web",
    description: "ENG QR code",
    width: "110px",
  },
];

export const QRMy = [
  {
    img: QR,
    link: "https://shopee.com.my/web",
    description: "MY QR code",
    width: "110px",
  },
];

//social media icons
export const socialsEng = [
  {
    img: reqSvgSocial(svgFileSocialPaths[0]),
    link: " https://www.facebook.com/ShopeePayMY ",
    description: "facebook icon",
  },
  {
    img: reqSvgSocial(svgFileSocialPaths[1]),
    link: "https://www.instagram.com/shopeepay_my/ ",
    description: "instagram icon",
  },
];

export const socialsMy = [
  {
    img: reqSvgSocial(svgFileSocialPaths[0]),
    link: " https://www.facebook.com/ShopeePayMY ",
    description: "facebook icon",
  },
  {
    img: reqSvgSocial(svgFileSocialPaths[1]),
    link: "https://www.instagram.com/shopeepay_my/ ",
    description: "instagram icon",
  },
  {
    img: reqSvgSocial(svgFileSocialPaths[2]),
    link: "https://www.instagram.com/shopeepay_my/",
    description: "twitter icon",
  },
];

export const socialsSg = [
  {
    img: reqSvgSocial(svgFileSocialPaths[0]),
    link: "https://www.facebook.com/ShopeePaySG/",
    description: "facebook icon",
    width: "36px",
  },
  {
    img: reqSvgSocial(svgFileSocialPaths[1]),
    link: "https://www.instagram.com/shopeepay_sg/",
    description: "instagram icon",
    width: "36px",
  },
  {
    img: telegramLogo,
    link: "https://t.me/shopeepaysg",
    description: "telegram icon",
    width: "36px",
  },
];

//app stores
export const appStoresEng = [
  {
    img: reqSvgApp(svgFileAppPaths[0]),
    link: " https://apps.apple.com/my/app/id959841113",
    description: "Apple Store",
    width: "120px",
  },
  {
    img: reqSvgApp(svgFileAppPaths[1]),
    link: "https://play.google.com/store/apps/details?id=com.shopee.my&referrer=af_tranid%3DZPoWZKWO_aJuSqfj4uXOCw%26pid%3DOrganicA%26c%3Dlp_home_and1 ",
    description: "Google Play",
    width: "120px",
  },
  {
    img: reqSvgApp(svgFileAppPaths[2]),
    link: "https://appgallery.huawei.com/app/C100576469?sharePrepath=ag&channelId=web&detailType=0 ",
    description: "Hua Wei Store",
    width: "120px",
  },
];

export const appStoresMy = [
  {
    img: reqSvgApp(svgFileAppPaths[0]),
    link: " https://apps.apple.com/my/app/id959841113 ",
    description: "Apple Store",
    width: "120px",
  },
  {
    img: reqSvgApp(svgFileAppPaths[1]),
    link: "https://play.google.com/store/apps/details?id=com.shopee.my&referrer=af_tranid%3DZPoWZKWO_aJuSqfj4uXOCw%26pid%3DOrganicA%26c%3Dlp_home_and1",
    description: "Google Play",
    width: "120px",
  },
  {
    img: reqSvgApp(svgFileAppPaths[2]),
    link: "https://appgallery.huawei.com/app/C100576469?sharePrepath=ag&channelId=web&detailType=0 ",
    description: "Hua Wei Store",
    width: "120px",
  },
];

export const appStoresSg = [
  {
    img: reqSvgApp(svgFileAppPaths[0]),
    link: "https://apps.apple.com/sg/app/id959840394",
    description: "Apple Store",
    width: "140px",
  },
  {
    img: reqSvgApp(svgFileAppPaths[1]),
    link: "https://play.google.com/store/apps/details?id=com.shopee.sg&referrer=af_tranid%3D2BZOxubH0RJZMV8iqvmiYQ%26pid%3DOrganicA%26c%3Dlp_home_and1",
    description: "Google Play",
    width: "140px",
  },
  {
    img: reqSvgApp(svgFileAppPaths[2]),
    link: "https://appgallery.huawei.com/#/app/C100936781?accessId=3f37628fe5aa43e8bbe09e24356043f7",
    description: "Hua Wei Store",
    width: "120px",
  },
];

//bank icons
export const bankIconsEng = [
  {
    img: reqPngIcons(pngFileIconsPaths[0]),
    width: "60px",
  },
  {
    img: reqPngIcons(pngFileIconsPaths[1]),
    width: "60px",
  },
  {
    img: reqPngIcons(pngFileIconsPaths[2]),
    width: "70px",
  },
  {
    img: reqPngIcons(pngFileIconsPaths[3]),
    width: "90px",
  },
];

export const bankIconsMy = [
  {
    img: reqPngIcons(pngFileIconsPaths[0]),
    width: "50px",
  },
  {
    img: reqPngIcons(pngFileIconsPaths[1]),
    width: "100px",
  },
  {
    img: reqPngIcons(pngFileIconsPaths[2]),
    width: "60px",
  },
  {
    img: reqPngIcons(pngFileIconsPaths[3]),
    width: "90px",
  },
  {
    img: reqPngIcons(pngFileIconsPaths[4]),
    width: "70px",
  },
  {
    img: reqPngIcons(pngFileIconsPaths[5]),
    width: "80px",
  },
  {
    img: reqPngIcons(pngFileIconsPaths[6]),
    width: "90px",
  },
  {
    img: reqPngIcons(pngFileIconsPaths[7]),
    width: "120px",
  },
];

//extreme left box text
export const footerLeftTextEng = "Follow us";
export const footerLeftTextMy = "Ikuti Kami";

//extreme left box download text
export const footerLeftDownloadEng = "Download Shopee app now";
export const footerLeftDownloadMy = "Muat turun aplikasi Shopee sekarang";

//right half left box title
export const footerLeftUpperTitleEng = "SERVICES";
export const footerLeftUpperTitleMy = "Perkhidmatan";

//right box title
export const footerRightUpperTitleEng = "EXPLORE SHOPEEPAY";
export const footerRightUpperTitleMy = "Kenali ShopeePay";

//Left box links
export const leftBoxLinksEng = {
  shopeePayFeatures: "ShopeePay Features",
  shopeePayApp: "Pay With ShopeePay",
  merchantFeatures: "Merchant Features",
  promo: "Promotion",
  faq: "FAQ",
};
export const leftBoxLinksMy = {
  shopeePayFeatures: " Ciri-Ciri ShopeePay",
  shopeePayApp: "Aplikasi ShopeePay",
  merchantFeatures: "Ciri-Ciri Peniaga",
  promo: "Promosi",
  faq: "Soalan Lazim",
};
//Right Box links
export const rightBoxLinksEng = {
  aboutUs: "About Us",
  termsConditions: "Terms & Conditions",
  PrivacyPolicy: "Privacy Policy",
  contactUs: "Contact Us",
};
export const rightBoxLinksMy = {
  aboutUs: "Tentang Kami ",
  termsConditions: "Terma dan Syarat",
  PrivacyPolicy: "Dasar Privasi",
  contactUs: "Hubungi Kami ",
};

export const footerLowerTextMy = "Cara Tambah Nilai";
export const footerLowerTextEng = "TOP-UP METHOD";

export const footerFootnoteEng =
  "Copyright © Shopee Limited. Trademarks belong to their respective owners. All rights reserved.";
export const footerFootnoteMy = `© Shopee ${new Date().getFullYear()}. Hak Cipta Dilindungi.`;
